<template>
  <div>
    <page-header
      :title="'Subscriptions'"
      :sub-heading="true"
      :sub-header="'Dashboard / Subscription '"
      :link-name="'Free Plan'"
      :link-url="'/subscriptions'"
    ></page-header>

    <div class="usp sub-page-container row">
      <div class="col-xs-12">
        <div class="usp__nav tab-nav">
          <router-link
            :to="{ name: 'free-subscription-plan' }"
            class="tab-nav__el"
            exact
            >Free Plan
          </router-link>
          <router-link
            :to="{ name: 'paid-subscription-plan' }"
            class="tab-nav__el"
            exact
            >Paid Plan
          </router-link>
          <router-link
            :to="{ name: 'cancelled-subscription-plan' }"
            class="tab-nav__el"
            exact
            >Cancelled Plan
          </router-link>
        </div>
        <div class="usp__content tab-content">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "../../layouts/components/fullPage/PageHeader";

export default {
  name: "Subscriptions",
  components: { PageHeader },
  data() {
    return {};
  },

  created() {
    // this.rowData = subscriptios;
  },
};
</script>

<style scoped></style>
